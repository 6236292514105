<template>
    <div class="chapter-list flex-column">
        <el-form class="searchForm" ref="searchForm" :model="searchData" :inline="true" @submit.native.prevent>
            <el-form-item label="章节搜索：">
                <el-input v-model="searchData.query_text" clearable placeholder="章节关键词"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchHandle">查询</el-button>
            </el-form-item>
        </el-form>
        <div class="chapter-list-head flex-row">
            <el-radio-group cca v-model="searchData.sort" @change="orderChangeHandle">
                <el-radio size="mini" :label="0">正序</el-radio>
                <el-radio size="mini" :label="1">倒序</el-radio>
            </el-radio-group>
            <div class="chapter-list-head-rt flex-row">
                <div v-if="sortData.number" class="mr10">你本次已经拖拽调整过 <span class="color-red">{{sortData.number}}</span> 次了</div>
                <el-button v-if="[1, 2].includes(level)" @click="addChapter">新增章节</el-button>
                <el-button v-if="sortData.number" type="primary" @click="saveSortHandle">保存排序</el-button>
            </div>
        </div>
        <dragTable
            :data="tableList"
            :border="true"
            :row-class-name="tableRowClassName"
            ref="table"
            dragHandle=".el-icon-move"
            :onEnd="dragEndHandle"
            style="width: 100%">
            <el-table-column type="selection" label="选择" align="center" width="50"></el-table-column>
            <el-table-column type="index" label="排序" align="center" width="50"></el-table-column>
            <el-table-column label="章节编号" prop="chapter_sn" align="center" width="120">
                <template slot-scope="scope">
                    <chapterFieldEdit :scope="scope" />
                </template>
            </el-table-column>
            <el-table-column label="章节名" prop="chapter_name" align="center" width="150">
                <template slot-scope="scope">
                    <chapterFieldEdit :scope="scope" />
                </template>
            </el-table-column>
            <el-table-column label="章节封面图" prop="chapter_cover_16_8" align="center" width="150">
                <template slot-scope="scope">
                    <img-preview :src="scope.row.chapter_cover_domain + $utils.filterImagePath(scope.row.chapter_cover_16_8)">
                       <img
                            width="76px"
                            height="38px"
                            :key="scope.row.chapter_cover_domain + $utils.filterImagePath(scope.row.chapter_cover_16_8)"
                            v-lazy="scope.row.chapter_cover_domain + $utils.filterImagePath(scope.row.chapter_cover_16_8)"
                        />
                    </img-preview>
                </template>
            </el-table-column>
            <el-table-column label="时长" align="center" width="120">
                <template slot-scope="scope">
                    {{scope.row.duration | reCovertTimeFilter}}
                </template>
            </el-table-column>
            <el-table-column label="金额" align="center" width="160">
                <template slot-scope="scope">
                    {{ scope.row.chapter_price }}
                </template>
            </el-table-column>
            <el-table-column label="发布平台及时间" align="center" width="230">
                <template slot-scope="scope">
                    <div v-for="platform in scope.row.platforms" :key="platform.platform_id" class="platform-col flex-row-between">
                        <div class="platform-col-name">{{formatPlatform(platform.platform_id)}}</div>
                        <div v-if="platform.is_timing" class="platform-col-time">{{platform.publish_time | timeFilter('yyyy-MM-dd hh:mm:ss')}}</div>
                        <div v-else class="platform-col-time">立即发布</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="理由" prop="audit_remark" align="center" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="操作" width="250" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain @click="previewHandle(scope.row)">预览</el-button>
                    <el-button size="mini" type="info" :disabled="scope.row.show_status === 0" plain @click="editHandle(scope.row)">修改</el-button>
                    <el-button v-if="scope.row.isDelete" size="mini" type="danger" plain @click="removeHandle(scope.$index, scope.row)">删除</el-button>
                    <el-button v-else size="mini" :type="scope.row.show_status === 0 ? 'danger': 'warning'" plain @click="displayHandle(scope.$index, scope.row)">{{scope.row.show_status === 0 ? '上架' : '下架'}}</el-button>
                </template>
            </el-table-column>
            <el-table-column label="排序值" prop="chapter_sn" align="center" width="150">
                <template slot-scope="scope">
                    <chapterSortEdit :sort="searchData.sort" :scope="scope" @getTableList = "takeSortGetTableList()" />
                </template>
            </el-table-column>
        </dragTable>
        <chapterView
            ref="chapterView"
            :animation_name="animation_name"
            :chapter_id.sync="currentChapter.chapter_id"
            :animation_id="animation_id"
        ></chapterView>
    </div>
</template>
<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-20 14:51:40
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-09-20 15:11:14
 * @Description: 章节列表
 * @Company: 成都二次元动漫
 */
import chapterFieldEdit from './chapterFieldEdit';
import chapterSortEdit from './chapterSortEdit';
import chapterView from './chapterView';
import dragTable from '@/components/table/dragTable';
import { dataMixin, paginationMixin, dialogMixin } from '@/mixins';
import { mapState } from 'vuex';
import dom from '@/libs/dom';
import ImgPreview from '@/components/img-preview';

export default {
    components: {
        dragTable,
        chapterView,
        chapterSortEdit,
        chapterFieldEdit,
        ImgPreview
    },
    mixins: [dataMixin, paginationMixin, dialogMixin],
    data() {
        return {
            link: null,
            animation_name: null,
            animation_id: null,
            chapter_price: null,
            pagination: {
                page_size: 100
            },
            searchData: {
                sort: 1,
                query_text: null
            },
            platformList: [],
            tableList: [],
            sortData: {
                animation_id: null,
                number: 0,
                list: []
            },
            currentChapter: {}
        };
    },
    computed: {
        ...mapState('app', {
            level: state => state.userInfo.level, // 1：用户 2：管理 3：超管
            userId: state => state.userInfo.id
        })
    },
    created() {
        const { animation_id, animation_name } = this.$route.query;
        this.animation_name = animation_name;
        this.animation_id = animation_id;
        this.sortData.animation_id = animation_id;
        this.getComicPlatformList();
        this.getTableList();
    },
    mounted() {
        const that = this;
        const scrollWrap = document.querySelector('.chapter-list .el-table__body-wrapper');
        scrollWrap.addEventListener('scroll', () => {
            const bodyWrap = dom.children(scrollWrap)[0];
            if (bodyWrap.clientHeight - 50 < scrollWrap.scrollTop + scrollWrap.clientHeight) {
                const { page_index, page_size, total } = that.pagination;
                if (page_index === that.$utils.pageTotal(page_size, total)) return false;
                that.nextPage();
            }
        });
    },
    methods: {
        tableRowClassName({row}) {
            if (row.show_status === 0) {
                return 'unshelve';
            }
        },
        // 获取漫画类型列表
        getComicPlatformList() {
            return this.$api('getPublicConfig').then(({ data }) => (this.platformList = data.platform_list));
        },
        // 格式化平台名称
        formatPlatform(platform_id) {
            const platform = this.platformList.find(platform => (platform.id === platform_id));
            if (platform) {
                return platform.name;
            }
            return '';
        },
        searchHandle() {
            this.tableList = [];
            this.getTableList(1);
        },
        // 排序类型切换
        orderChangeHandle() {
            this.searchHandle();
        },
        // 预览章节
        previewHandle({animation_id, chapter_video_id}) {
            if (!this.link) {
                this.link = document.createElement('a');
            }
            this.$api('getAnChapterVideoPlayAuth', {
                animation_id,
                chapter_video_id
            }).then(res => {
                console.log(res);
                this.link.setAttribute('href', `/static/video_preview.html?playauth=${res.data.PlayAuth}&vid=${chapter_video_id}`);
                this.link.setAttribute('target', '_blank');
                this.link.click();
            });
        },
        // 新增章节
        addChapter() {
            const { animation_id, animation_name, authorName, uploaderUid } = this.$route.query;
            this.$router.push({ name: 'editAnChapter', query: { animation_id, animation_name, uploaderUid, authorName, type: 'add', chapter_price: this.chapter_price } });
        },
        // 编辑章节
        editHandle({ animation_id, chapter_id }) {
            const { authorName, uploaderUid } = this.$route.query;
            this.$router.push({ name: 'editAnComicChapter', query: { type: 'edit', animation_name: this.animation_name, animation_id, uploaderUid, chapter_id, authorName, tabType: 2, single: 1 } });
        },
        // 删除章节
        removeHandle(index, row) {
            const { animation_id, chapter_id } = row;
            const { animation_name, authorName, uploaderUid } = this.$route.query;
            this.$utils.confirm('', '确定删除吗？', '删除中...', (action, instance, done) => {
                return this.$api('deleteComicChapter', { animation_id, chapter_id, sourceObj: { ...row, animation_name, authorName, uploaderUid } }).then(res => {
                    this.$message.success('删除成功！');
                    this.tableList.splice(index, 1);
                });
            });
        },
        // 上/下架章节
        displayHandle(index, row) {
            const { animation_id, chapter_id, show_status } = row;
            const msg = show_status === 0 ? '上架' : '下架';
            this.$utils.confirm('', `确定${msg}吗`, `${msg}中...`, (action, instance, done) => {
                return this.$api('displayAnComicChapter', { animation_id, chapter_ids: [chapter_id], show_status: show_status === 1 ? 0 : 1 }).then(res => {
                    this.$message.success(`${msg}成功！`);
                    row.show_status = show_status === 1 ? 0 : 1;
                    this.tableList.splice(index, 1, row);
                });
            });
        },
        // 查询列表
        getTableList(page_index) {
            if (page_index) this.pagination.page_index = page_index;
            return this.$api('getAnComicChapterList', { animation_id: this.$route.query.animation_id, ...this.searchData, ...this.pagination }).then(res => {
                this.chapter_price = res.data.main.chapter_price
                const { rows, page_index, page_size, total } = res.data.chapter;
                this.tableList = [...this.tableList, ...rows];
                this.pagination.page_index = page_index;
                this.pagination.page_size = page_size;
                this.pagination.total = total;
            });
        },
        // 统一操作列表查询，refresh是刷新，因为是懒加载所以在刷新的时候需要替换某一页，由于是可以拖拽，所以需要将所有已经加载的页数据都替换了
        getTableListHandle(refresh) {
            const { page_index } = this.pagination;
            // 更新
            if (refresh) {
                this.tableList = [];
                // 多页更新
                if (page_index > 1) {
                    const arr = Array.from(Array(page_index), (v, k) => k);
                    Promise.all(arr.map((item, index) => this.getTableList(index + 1)));
                } else if (page_index === 1) { // 单页更新
                    this.getTableList();
                }
            } else { // 不更新，追加
                this.getTableList();
            }
        },
        // 更新排序值，刷新列表
        takeSortGetTableList() {
            this.tableList = [];
            this.sortTemp = true;
            this.getTableList(1);
        },
        // 拖拽结束
        dragEndHandle({ oldIndex, newIndex }) {
            if (oldIndex === newIndex) return false;
            this.saveToggleDargTableData(oldIndex, newIndex);
        },
        // 获得变化的数据
        saveToggleDargTableData(oldIndex, newIndex) {
            let mv;
            const min = Math.min(oldIndex, newIndex);
            const len = Math.max(oldIndex, newIndex) - min + 1;
            if (!this.sortData.list.length) {
                this.sortData.list = this.tableList.concat();
            }

            const changeList = this.sortData.list.concat().splice(min, len);
            if (oldIndex < newIndex) {
                mv = changeList.shift();
                changeList.push(mv);
            } else if (oldIndex > newIndex) {
                mv = changeList.pop();
                changeList.unshift(mv);
            }
            ++this.sortData.number;
            this.sortData.list.splice(min, len, ...changeList);
        },
        // 获得变化的数据在总数据的序列
        getDragDataIndex(changeList, oldDataList) {
            const tmpArr = [];
            const { sort } = this.searchData;
            changeList.forEach((item, index) => {
                oldDataList.some((v, k) => {
                    // 如果改变后的数据章节id和原始数据章节id相等，且这是他们所对应的下标不相等说明是被更改后的数据
                    if (item.chapter_id === v.chapter_id && index !== k) {
                        tmpArr.push({
                            chapter_id: item.chapter_id,
                            ordernum: sort === 1 ? index + 1 : this.pagination.total - index
                        });
                        return true;
                    }
                });
            });
            return tmpArr;
        },
        // 下一页
        nextPage() {
            this.pagination.page_index += 1;
            this.getTableListHandle();
        },
        // 分页
        pageChangeHandle({ page, size }) {
            this.getTableListHandle();
        }
    }
};
</script>

<style lang="scss">
    .chapter-list{
        height: 100%;
        .el-table{
            flex-grow: 1;
            .el-table__body-wrapper{
                height: calc(100% - 40px);
                position: relative;
                overflow-y: auto;
            }
            .el-table__row.unshelve {
                background-color: #ccc;
            }
        }
        &-head{
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            &-rt{
                flex-grow: 1;
                justify-content: flex-end;
                align-items: center;
            }
        }
        .el-icon-move{
            font-size: 24px;
            color: #ccc;
            cursor: move;
        }
        .platform-col{
            &-name{

            }
            &-time{

            }
        }
    }
</style>
