<template>
  <div class="preview-video">
      <el-dialog title="预览" :visible.sync="isShow">

      </el-dialog>
  </div>
</template>
<script>
export default {
    data() {
        return {
            isShow: false
        };
    },
    methods: {
        openDialog() {
            this.isShow = true;
        }
    }
};
</script>
