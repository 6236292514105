<template>
    <div class="chapter-field">
        <div v-if="!editActive" class="flex-row-between">
            <div>{{scope.row[scope.column.property]}}</div>
            <div @click="editHandle"><i class="el-icon-edit color-green"></i></div>
        </div>
        <div v-else class="flex-row-between">
            <el-input-number v-if="scope.column.property === 'chapterPrice'" v-model="scope.row[scope.column.property]" size="mini" :min="0" :max="500" label="描述文字" @keyup.enter.native.prevent="submitHandle"></el-input-number>
            <el-input
                maxlength="11"
                v-acgn-number="2"
                v-else-if="scope.column.property === 'gridNum'"
                class="mr10"
                size="mini"
                v-model="scope.row[scope.column.property]"
                @keyup.enter.native.prevent="submitHandle"
            ></el-input>
            <el-input v-else class="mr10" size="mini" v-model="scope.row[scope.column.property]" @keyup.enter.native.prevent="submitHandle"></el-input>
            <el-button type="text" icon="el-icon-check" @click.stop="submitHandle"></el-button>
            <el-button type="text" icon="el-icon-close" @click.stop="cancleHandle"></el-button>
        </div>
    </div>
</template>

<script>
/*
 * @Author: daipeng
 * @Date: 2018-09-21 13:33:56
 * @LastEditors: OBKoro1
 * @LastEditTime: 2018-10-18 09:13:59
 * @Description: 章节列表-行内编辑
 * @Company: 成都二次元动漫
 */

export default {
    props: {
        scope: Object
    },
    data() {
        return {
            editActive: false,
            cloneRowData: null
        };
    },
    methods: {
        // 开始编辑
        editHandle() {
            this.editActive = true;
            this.cloneRowData = this.$utils.cloneDeep(this.scope.row);
        },
        // 取消编辑
        cancleHandle() {
            this.editActive = false;
            this.scope.row[this.scope.column.property] = this.cloneRowData[this.scope.column.property];
        },
        // 确认修改
        submitHandle() {
            const { column: { property }, row } = this.scope;
            const { animation_id, chapter_id } = row;

            if (row[property] === this.cloneRowData[property]) {
                this.editActive = false;
                return false;
            }

            this.$api('editAnComicChapterField', { animation_id, chapter_id, field: property, value: row[property] }).then(res => {
                this.$message.success(`修改成功！`);
                this.editActive = false;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    .chapter-field{

    }
    .el-icon-edit{
        cursor: pointer;
    }
</style>
