<script>
import Sortable from 'sortablejs';
export default {
    props: {
        data: Array,
        draggable: String,
        rowClassName: Function,
        sort: {
            type: Boolean,
            default: true
        },
        border: Boolean,
        // 拖动手柄
        dragHandle: {
            type: String,
            required: true
        },
        onStart: Function,
        onAdd: Function,
        onEnd: Function,
        onUpdate: Function,
        onSort: Function
    },
    updated() {},
    mounted() {
        this.dragTable = Sortable.create(this.$el.querySelector('.el-table__body-wrapper tbody'), {
            sort: this.sort,
            handle: this.dragHandle,
            onStart: this.onStart,
            onEnd: this.onEnd,
            onAdd: this.onAdd,
            onUpdate: this.onUpdate,
            onSort: this.onSort
        });
    },
    render(h) {
        return (
            <el-table {...{on: this.$listeners}} data={this.data} border={this.border} rowClassName={this.rowClassName}>
                {this.$slots.default}
            </el-table>
        );
    }
};
</script>
